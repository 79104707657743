@import "../../breakpoints.scss";

.subPage{
    display: block;
    position: relative;

    @include breakpoint(md) {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "mainImg gallery";
      }

    .container{
        position: relative;
        @include breakpoint(md) {
            overflow: hidden;
        }

        &.content{
            grid-area: content;
            width: 100vw;

            @include breakpoint(md) {
                width: 55vw;
            }
        }

        &.main{
            grid-area: mainImg;
            width: 100vw;

            @include breakpoint(md) {
                width: 45vw;
            }
        }
    }
}
