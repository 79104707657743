@import "../../breakpoints.scss";

.homeGallery{
  display: flex;
  overflow: auto;
  width: 100%;
  padding: 20px 10px;
  margin: 0;
  height: calc(100vh - 90px);
  margin-top: 90px;
  flex-direction: column;
  align-items: center;

  @include breakpoint(sm) {
    padding: 20px 0 20px 60px;
    flex-direction: row;
    overflow: hidden;
  }

  .slick-slider{
    .slick-arrow{
      top: inherit;
      bottom: 0px;
      z-index: 100;

      @include breakpoint(sm) {
        bottom: -20px;
      }

      &::before{
        font-size: 50px;
      }
      &.slick-next{
        left:80px;
        right: auto;

        @include breakpoint(sm) {
          left:80px;
        right: auto;
        }
      }
      &.slick-prev{
        left:5px;
        right: auto;
      }      
    }
  }

  .slick-slide{
      padding: 0 10px;
  }

  .slide{
    position: relative;
    list-style: none;
    max-height: 75vh;
    height: 100vh;
    width: calc(100vw - 35px);
    overflow: hidden;
    margin: 0 0 20px 0;

    @include breakpoint(sm) {
      display: block;
      width: auto;
    }
    



    img{
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
    }

    .portInfo{
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 15px;
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.75)));
      background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.75) 100%);
      background: -o-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.75) 100%);
      background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.75) 100%);
      background: linear-gradient(to bottom,
      rgba(0,0,0,0) 0%,rgba(0,0,0,0.75) 100%);

      a{
          color:#fff;
          text-decoration: none;
      }

      h3{
        margin-bottom: 10px;
        color: #fff;
      }

      p{
        font-size: 12px;
        font-style: italic;
        font-weight: 500;
        color: #fff;
        opacity: 0.6;
        width: 80%;
      }
    }


  }
}
