*{box-sizing: border-box;}
*:focus {outline:none !important}
*:active {outline:none;}
@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,400i,500,500i,700,700i&display=swap');

@import "breakpoints.scss";

html, body{
  margin: 0;
  padding: 0;
  background: #1B1B1B;
  height: auto;
  font-family:
  'Ubuntu', sans-serif;
  overflow-x: hidden;
}

.App{
  width: 100vw;
  height: auto;
  overflow: hidden;
  padding: 0;

  header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 90px;
    z-index: 10;
    padding: 0 40px;
    background: url(assets/img/header.jpg);
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include breakpoint(sm) {
      padding: 0 70px;
    }

    .logo{
      max-height: 50px;
      width: auto;
      height: 200px;
    }

    nav{
      
      justify-content: center;
      align-items: center;
      color:#eee;
      text-transform: uppercase;
      font-size: 12px;
      outline: none;

      button{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
        background: none;
        border: none;
        outline: none;

        &:focus, &:hover, &:active, &::-moz-focus-inner {
          border: 0;
        outline:none;}

        span{
          border: 2px solid #eee;
          display: block;
          width: 30px;
          margin: 2px 0;
          transition: all 0.3s ease-in-out;
          opacity: 1;
          outline: none;
        }

        &.open span{
          opacity: 0;
        }

        &.open span:first-child{
          transform: rotateZ(45deg) translateX(6px) translateY(5px);
          opacity: 1;
        }

        &.open span:last-child{
          transform: rotateZ(-45deg) translateX(6px) translateY(-5px);
          opacity: 1;
        }        

        @include breakpoint(sm) {
          display: none;
        }
      }


      a{
        color:#eee;
        text-decoration: none;
      }

      ul{
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: block;
        position: fixed;
        top: 90px;
        left: 0;
        width: 100%;

        transition: all 0.2s ease-in-out;
        @include breakpoint(xs){
          opacity: 0;
          visibility: hidden;
          display: flex;
          position: fixed;
          width: 100vw;
          left: 0;
          top:100%;
          flex-direction: column;
          background-color: rgba(0,0,0,0.7);
          z-index: 100;
          padding: 20px 0;
          height: auto;

          li{
            min-height: 50px;
            width: 100%;
            text-align: center;
            flex-direction: column;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
  
            &.submenu{
              position: static;
              .subnav{
                opacity: 0;
                visibility: hidden;
                position: fixed;
                width: 100%;
                padding: 10px;
                height: auto;
                left: 100%;
  
                li{
                  text-align: center;
                  line-height: 0;
                  height: 0;
                }
              }
            }
        }
      }


        @include breakpoint(sm) {
          display: flex;
          position: static;
          flex-direction: row;
          align-items: center;
          justify-content: space-evenly;
          width: auto;
        }

        li{
          @include breakpoint(sm) {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            list-style-type: none;
            padding: 0;
            margin: 0 10px;
            height: 90px;
          }

        }

        .submenu{
          position: relative;
          cursor: pointer;

          .subnav{
            @include breakpoint(sm) {
             // display: none;
              width: 200px;
              transition: all 0.2s ease-in-out;
              opacity: 0;
              bottom: 0;
              top: 120px;
              left: 0px;
              position: absolute;
              visibility: hidden;
              padding: 10px;
              background-color: rgba(0,0,0,0.71);
              z-index: 200;
              height: 180px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: space-evenly;
            }

              li{
                display: block;
                height: 30px;   
                margin: 0;
                padding: 10px;

              }
          }

          &:hover .subnav{
            visibility: visible;
            opacity: 1;
            top: 90px;
          }

        }
      }
      @include breakpoint(xs) {
      &.open ul{
        display: flex;
        position: fixed;
        width: 100vw;
        left: 0;
        top:90px;
        flex-direction: column;
        background-color: rgba(0,0,0,0.7);
        z-index: 100;
        padding: 20px 0;
        opacity: 1;
        visibility: visible;
        height: auto;

        li{
          height: auto;
          min-height: 50px;
          width: 100%;
          text-align: center;
          flex-direction: column;
          padding: 10px 0;
          display: flex;

          &.submenu{
            position: relative;
            &::after{
              content: "→";
              position: absolute;
              right: 30%;

            }
            .subnav{
              opacity: 1;
              visibility: visible;
              position: fixed;
              width: 100%;
              padding: 10px;
              height: auto;
              left: 100%;
              

              li{
                line-height: 30px;
                text-align: center;
              }
            }
          }


        }
      }

      &.open.subOpen ul{
        left: -100%;

        .subnav{
          left:0 !important;
          opacity: 1;
          visibility: visible;
          position: relative;

          .back::before{
            content: "←";
            position: absolute;
            left: 30%;
          }
        }
      }
    }
    }
  }
}
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 450ms ease-in;
}

.fadeIn{
  opacity: 0;
  z-index: 1;
}

.fadeIn-end{
  opacity: 1;
  transition: opacity 450ms ease-in;
}