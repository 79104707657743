.formWrapper{
    width: 100%;
    max-width: 768px;

    .text{
        opacity: 0;
        visibility: hidden;
        transition: all .5s ease-in-out;
        border: 0;
        margin-top: 0;

        &.show{
            opacity: 1;
            visibility: visible;
        }
    }
}

.contactForm{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 768px;
    padding-bottom: 3em;
    transition: all .5s ease-in-out;
    opacity: 1;

    &.submit{
        opacity: 0;
        height: 0;
        overflow: hidden;
        padding-bottom: 0;
    }

    .foobar{
        display: none;
    }

    input[type=text],input[type=email]{
        width: 100%;
    }

    input[type=submit]{
        width: 100%;
        max-width: 150px;
        height: 55px;
        background-color: rgba(255,255,255,0.05);
        font-style: normal;
        font-weight: 500;
    }

    input{
        border: 0;
        background-color: rgba(255,255,255,0.1);
        height: 60px;
        margin-top: 15px;
        padding: 20px;
        font-family: 'Ubuntu', sans-serif;
        font-style: italic;
        color: #ccc;
    }

    textarea{
        width: 100%;
        height: 20vh;
        padding: 10px;
        border: 0;
        background-color: rgba(255,255,255,0.1);
        margin-top: 15px;
        padding-left: 20px;
        font-family: 'Ubuntu', sans-serif;
        font-style: italic;
        font-size: 12px;
        padding-top: 15px;
        color: #ccc;
    }
}