@import "../../breakpoints.scss";

.PortfolioMainImg{
  overflow: hidden;
  height: calc(100vh - 90px);
  width: 100vw;
  margin-top: 90px;
  position: relative;
  grid-area: mainImg;
  position: static;
  z-index: 99;

  @include breakpoint(md) {
    position: fixed;
    width: 45vw;
}

  .back{
      width: 100px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: black;
      color:#eee;
      position: absolute;
      top: 0;
      left:0;
      font-size: 12px;
      cursor: pointer;
      z-index: 1;
  }

  &::after{
      position: absolute;
      display: block;
      height: 80px;
      width: 80px;
      border-bottom: 1px solid #aaa;
      border-left: 1px solid #aaa;
      bottom: 30px;
      left: 30px;
      z-index: 1;
      content: "";
  }

  &::before{
      position: absolute;
      display: block;
      height: 80px;
      width: 80px;
      border-top: 1px solid #aaa;
      border-right:1px solid #aaa;
      top: 30px;
      right: 30px;
      z-index: 1;
      content: "";
  }

  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;

  }
}
