@import "../../breakpoints.scss";

.PortfolioGallery{
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px 0px;
  

  position: relative;

  @include breakpoint(md) {
    width: 55vw;
    padding: 0 60px 0px;
    margin-top:90px;
  }

  h2{
      text-transform: uppercase;
      color: #eee;
      margin-top: 50px;
      margin-bottom: 10px;
      max-width: 764px;
      text-align: left;
      width: 100%;
  }

  .text{
      color: #ddd;
      margin-bottom: 30px;
      font-size: 12px;
      font-style: italic;
      padding-bottom: 30px;
      border-bottom: 1px solid #aaa;
      width: 100%;
      max-width: 764px;
      position: relative;
      z-index: 1;
  }

  .number{
      position: absolute;
      bottom: 0;
      right: 0;
      font-style: normal;
      font-weight: bold;
      font-size: 144px;
      color: rgba(255, 255, 255, 0.1);
      z-index: 0;
  }

  .my-gallery-class{
      padding: 0;
      margin: 0 0 30px 0;
      list-style-type: none;
      max-width: 764px;
      width: 100%;

      @include breakpoint(md) {
        margin: 0 0 150px 0;
      }

      li{
          position: relative;
          float: left;
          overflow: hidden;
          height: 350px;
          padding: 5px;

          .border{
            width: 100%;
            height: 100%;
            overflow: hidden;
          }


          img{
              width: 100%;
              object-fit: cover;
              height: 100%;
              transition: transform 0.5s ease;
          }
      }

      .masonry-brick{
          width: 100%;

          @include breakpoint(lg) {
            width: 50%;
          }

          @include breakpoint(xl) {
            width: 33.3%;
          }


          &:hover img{
             transform: scale(1.05);
          }
      }

      .masonry-brick-large{
        width: 100%;

        @include breakpoint(lg) {
            width: 50%;
          }

          @include breakpoint(xl) {
            width: 66.6%;
          }

          &:hover img{
             transform: scale(1.05);
          }
      }
  }


}
