@import "../../breakpoints.scss";

.contentNav{

    
    background: #000;
    color: #eee;
    
    bottom: 0;
    right: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    @include breakpoint(md) {
        position: fixed;
        width: 55%;
        flex-direction: row;
        justify-content: space-between;
        height:90px;
        padding: 0 50px;
      }

    div{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 30px;

        @include breakpoint(md) {
            width: 50%;
            padding: 0;
          }

        a{
            color: #eee;
            display: block;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            outline: none;
        }

        &:first-child{
            border-bottom: 1px solid #666;

            @include breakpoint(md) {
                border-bottom: 0;
                border-right: 1px solid #666;
              }
        }
    }

    .next{
        justify-content: flex-end;

        img{
            margin-left: 30px;
        }
    }

    .prev{
        img{
            margin-right: 30px;
        }
    }

    img{
        border-radius: 50%;
        max-width: 50px;
        height: auto;
    }
}
