@import "../../breakpoints.scss";

.PageContent{
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px 0px;

  position: relative;

  @include breakpoint(sm) {
    width: 55vw;
    padding: 0 60px 0px;
    margin-top:90px;
  }

  h2{
      text-transform: uppercase;
      color: #eee;
      margin-top: 50px;
      margin-bottom: 10px;
      max-width: 764px;
      text-align: left;
      width: 100%;
      padding-bottom: 30px;
      border-bottom: 1px solid #aaa;
  }

  .text{
      color: #ddd;
      margin-bottom: 30px;
      font-size: 12px;
      font-style: italic;
      padding-bottom: 30px;
      border-bottom: 1px solid #aaa;
      width: 100%;
      max-width: 764px;
      position: relative;
      z-index: 1;
      line-height: 22px;

      a{
        color:#fff;
      }
  }

  

 


}
